.MuiAutocomplete-listbox .Mui-focused {
  background: linear-gradient(
    270.16deg,
    #bc8bfb 0.13%,
    #933eff 98.35%
  ) !important;
  color: white;
}
.MuiAutocomplete-listbox .Mui-focused > span {
  color: white !important;
}

.MuiAutocomplete-option {
    width: 100% !important;
    padding: 8px 8px !important;
}
.MuiAutocomplete-listbox{
   padding: 0 !important;
} 